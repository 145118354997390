import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _03e779e4 = () => interopDefault(import('../pages/blogs/index.vue' /* webpackChunkName: "pages/blogs/index" */))
const _4f8631b0 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _a25c0336 = () => interopDefault(import('../pages/evenementen/index.vue' /* webpackChunkName: "pages/evenementen/index" */))
const _146024cb = () => interopDefault(import('../pages/gegevens-aanpassen.vue' /* webpackChunkName: "pages/gegevens-aanpassen" */))
const _cd6ecdde = () => interopDefault(import('../pages/inloggen.vue' /* webpackChunkName: "pages/inloggen" */))
const _75a46834 = () => interopDefault(import('../pages/magazine.vue' /* webpackChunkName: "pages/magazine" */))
const _a06d929c = () => interopDefault(import('../pages/magazines/index.vue' /* webpackChunkName: "pages/magazines/index" */))
const _7145b87c = () => interopDefault(import('../pages/menu/index.vue' /* webpackChunkName: "pages/menu/index" */))
const _4c1e62e7 = () => interopDefault(import('../pages/profiel.vue' /* webpackChunkName: "pages/profiel" */))
const _63b0eba5 = () => interopDefault(import('../pages/recepten/index.vue' /* webpackChunkName: "pages/recepten/index" */))
const _5b2bd462 = () => interopDefault(import('../pages/registreren.vue' /* webpackChunkName: "pages/registreren" */))
const _3c146801 = () => interopDefault(import('../pages/services/index.vue' /* webpackChunkName: "pages/services/index" */))
const _5a966dd9 = () => interopDefault(import('../pages/verifieer.vue' /* webpackChunkName: "pages/verifieer" */))
const _0b62b15f = () => interopDefault(import('../pages/wachtwoord-aanpassen.vue' /* webpackChunkName: "pages/wachtwoord-aanpassen" */))
const _6e3360f5 = () => interopDefault(import('../pages/wachtwoord-vergeten.vue' /* webpackChunkName: "pages/wachtwoord-vergeten" */))
const _524879c6 = () => interopDefault(import('../pages/welbewust-assortiment/index.vue' /* webpackChunkName: "pages/welbewust-assortiment/index" */))
const _71093d34 = () => interopDefault(import('../pages/zoekresultaten.vue' /* webpackChunkName: "pages/zoekresultaten" */))
const _4a758776 = () => interopDefault(import('../pages/menu/samenstellen.vue' /* webpackChunkName: "pages/menu/samenstellen" */))
const _016c3e08 = () => interopDefault(import('../pages/services/aanvragen.vue' /* webpackChunkName: "pages/services/aanvragen" */))
const _ac90b10a = () => interopDefault(import('../pages/templates/default.vue' /* webpackChunkName: "pages/templates/default" */))
const _2342eb62 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _0230d89c = () => interopDefault(import('../pages/blogs/_slug.vue' /* webpackChunkName: "pages/blogs/_slug" */))
const _a5c945c6 = () => interopDefault(import('../pages/evenementen/_slug.vue' /* webpackChunkName: "pages/evenementen/_slug" */))
const _a3dad52c = () => interopDefault(import('../pages/magazines/_slug.vue' /* webpackChunkName: "pages/magazines/_slug" */))
const _61fa4a5d = () => interopDefault(import('../pages/recepten/_slug.vue' /* webpackChunkName: "pages/recepten/_slug" */))
const _3a5dc6b9 = () => interopDefault(import('../pages/services/_slug.vue' /* webpackChunkName: "pages/services/_slug" */))
const _d2255fcc = () => interopDefault(import('../pages/wachtwoord-instellen/_token.vue' /* webpackChunkName: "pages/wachtwoord-instellen/_token" */))
const _55b5bc56 = () => interopDefault(import('../pages/welbewust-assortiment/_slug.vue' /* webpackChunkName: "pages/welbewust-assortiment/_slug" */))
const _705792ba = () => interopDefault(import('../pages/*.vue' /* webpackChunkName: "pages/*" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/blogs",
    component: _03e779e4,
    name: "blogs"
  }, {
    path: "/contact",
    component: _4f8631b0,
    name: "contact"
  }, {
    path: "/evenementen",
    component: _a25c0336,
    name: "evenementen"
  }, {
    path: "/gegevens-aanpassen",
    component: _146024cb,
    name: "gegevens-aanpassen"
  }, {
    path: "/inloggen",
    component: _cd6ecdde,
    name: "inloggen"
  }, {
    path: "/magazine",
    component: _75a46834,
    name: "magazine"
  }, {
    path: "/magazines",
    component: _a06d929c,
    name: "magazines"
  }, {
    path: "/menu",
    component: _7145b87c,
    name: "menu"
  }, {
    path: "/profiel",
    component: _4c1e62e7,
    name: "profiel"
  }, {
    path: "/recepten",
    component: _63b0eba5,
    name: "recepten"
  }, {
    path: "/registreren",
    component: _5b2bd462,
    name: "registreren"
  }, {
    path: "/services",
    component: _3c146801,
    name: "services"
  }, {
    path: "/verifieer",
    component: _5a966dd9,
    name: "verifieer"
  }, {
    path: "/wachtwoord-aanpassen",
    component: _0b62b15f,
    name: "wachtwoord-aanpassen"
  }, {
    path: "/wachtwoord-vergeten",
    component: _6e3360f5,
    name: "wachtwoord-vergeten"
  }, {
    path: "/welbewust-assortiment",
    component: _524879c6,
    name: "welbewust-assortiment"
  }, {
    path: "/zoekresultaten",
    component: _71093d34,
    name: "zoekresultaten"
  }, {
    path: "/menu/samenstellen",
    component: _4a758776,
    name: "menu-samenstellen"
  }, {
    path: "/services/aanvragen",
    component: _016c3e08,
    name: "services-aanvragen"
  }, {
    path: "/templates/default",
    component: _ac90b10a,
    name: "templates-default"
  }, {
    path: "/",
    component: _2342eb62,
    name: "index"
  }, {
    path: "/blogs/:slug",
    component: _0230d89c,
    name: "blogs-slug"
  }, {
    path: "/evenementen/:slug",
    component: _a5c945c6,
    name: "evenementen-slug"
  }, {
    path: "/magazines/:slug",
    component: _a3dad52c,
    name: "magazines-slug"
  }, {
    path: "/recepten/:slug",
    component: _61fa4a5d,
    name: "recepten-slug"
  }, {
    path: "/services/:slug",
    component: _3a5dc6b9,
    name: "services-slug"
  }, {
    path: "/wachtwoord-instellen/:token?",
    component: _d2255fcc,
    name: "wachtwoord-instellen-token"
  }, {
    path: "/welbewust-assortiment/:slug?",
    component: _55b5bc56,
    name: "welbewust-assortiment-slug"
  }, {
    path: "/*",
    component: _705792ba,
    name: "*"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
